const LoadingPage = () => {
  return (
    <div className="w-screen h-screen flex flex-col items-center justify-center">
      <p className="text-primary text-lg">Loading page...</p>
      <span className="loading loading-ball loading-lg text-primary"></span>
    </div>
  );
};

export default LoadingPage;
